<template>
    <div class="relative" :class="[`h-${height}`]">
      <button class="btn_prev" aria-label="Anterior" @click="prevSlide" :disabled="slideIndex === 0">
        <i class="fas fa-arrow-left arrow_left"></i>
      </button>
      <button class="btn_next" aria-label="Próximo" @click="nextSlide" :disabled="slideIndex === 3">
        <i class="fas fa-arrow-right arrow_right"></i>
      </button>
      <carousel :perPage="1" loop:pagination-enabled="false" :navigate-to="slideIndex">
        <slide>
          <div class="cards" :class="[`h-${height}`]">
            <div class="card">
              <img  class="card_icon" :src="items[slideIndex].src" alt="Item"/>
              <p class="card_text">{{items[slideIndex].text}}</p>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
</template>

<script>
import { setInterval, clearInterval } from 'timers'
export default {
  name: 'carousel-items',
  props: {
    height: {
      type: Number,
      default: 64
    },
    items: {
      type: Array
    }
  },
  data () {
    return {
      slideIndex: 0,
      interval_id: null
    }
  },
  mounted () {
    this.startCarousel()
  },
  methods: {
    nextSlide () {
      if (this.slideIndex < (this.items.length - 1)) this.slideIndex += 1
      this.stopCarousel()
      this.startCarousel()
    },
    prevSlide () {
      if (this.slideIndex > 0) this.slideIndex -= 1
      this.stopCarousel()
      this.startCarousel()
    },
    startCarousel () {
      this.interval_id = setInterval(() => {
        if (this.slideIndex < (this.items.length - 1)) {
          this.slideIndex += 1
        } else {
          this.slideIndex = 0
        }
      }, 6000)
    },
    stopCarousel () {
      clearInterval(this.interval_id)
    }

  }

}
</script>

<style lang="sass" scoped>
@tailwind utilities
.cards
  @apply relative w-full flex flex-col items-center justify-center

.card
  @apply relative flex flex-col items-center w-full mt-4

.card_icon
  @apply w-40 h-20 bg-contain bg-center bg-no-repeat mb-10

.card_text
  @apply w-48 text-center text-gray-700 text-sm

.btn_prev
  @apply absolute left-0 inset-y-0 my-auto z-40 flex justify-center items-center bg-primary text-white h-8 w-8 rounded-full
  &:hover, &:focus
    @apply outline-none
  &[disabled]
    @apply opacity-50

.btn_next
  @apply absolute right-0 inset-y-0 my-auto z-40 flex justify-center items-center bg-primary text-white h-8 w-8 rounded-full
  &:hover, &:focus
    @apply outline-none
  &[disabled]
    @apply opacity-50

.arrow_left
  @apply bg-no-repeat bg-contain text-secondary h-4 w-4

.arrow_right
  @apply bg-no-repeat bg-contain text-secondary h-4 w-4

</style>
