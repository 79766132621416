<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="modal_content">
      <div class="logo" @click="$emit('closeModal')"></div>
      <div class="flex">
        <button class="btn btn_business" @click="$router.push({ path: '/tomador/painel' })">{{$store.state.isLoggedIn ? 'Empresa' : 'Sou empresa'}}</button>
        <button class="btn btn_investor" @click="$router.push({ path: '/investidor/painel' })">{{$store.state.isLoggedIn ? 'Investidor' : 'Sou investidor'}}</button>
      </div>
      <span>
        <router-link
          to="/parceiro/login"
          class="link">Entrar como parceiro
        </router-link></span>
    </div>
    <div class="footer">
      Copyright © TuTu Digital. Todos os Direitos Reservados.
      Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida
      Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>

.backdrop
  @apply fixed z-10 w-full h-full top-0 left-0
  background: rgba(0,0,0,0)

.modal
  @apply relative fixed flex flex-col z-20 w-screen h-screen justify-center items-center

.modal_content
  @apply h-full w-1/2 flex flex-col items-center justify-center mb-10 z-20

.logo
  @apply bg-no-repeat h-16 w-48 cursor-pointer
  background-image: url("../../assets/images/logo.svg")

.btn
  @apply w-64 h-20 text-white text-2xl mt-32 mb-auto
  &:hover, &:focus
    @apply outline-none

.btn_business
  @apply rounded-l-full bg-primary

.btn_investor
  @apply rounded-r-full bg-secondary

.footer
  @apply text-center text-xs text-gray-600 mb-4 w-5/12 leading-relaxed


.link
  @apply cursor-pointer
  @screen md
    @apply text-base font-medium text-secondary mr-10
  @screen lg
    @apply text-base font-medium text-secondary mr-10
</style>
